import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee, EmployeeResponse, EmployeesResponse } from '../../_common/model/employee';
import { SortAndPage } from '../../_common/model/sort-and-page';
import { ApiUrlService } from '../../_common/services/api-url.service';
import { HttpClientService } from '../../_common/services/http-client.service';
import { ReservationDetails } from '../reservations/model/reservation-details';
import {
    CREATE_EMPLOYEE,
    DELETE_EMPLOYEE,
    EmployeeDeleteResponse,
    EmployeeExternalIdQuery,
    EmployeesQuery,
    GET_EMPLOYEE,
    GET_EMPLOYEES,
    GET_EMPLOYEE_ID_BY_MEDIFIT_CUSTOMER_ID,
    UPDATE_EMPLOYEE,
} from './workers.graphql';

@Injectable({
    providedIn: 'root',
})
export class WorkersService {
    constructor(
        private helperUrl: ApiUrlService,
        private httpClient: HttpClientService,
        private apollo: Apollo,
    ) {}

    public getBusinessSubjectCasesForWorker(bsid: number, workerId: number): Observable<ReservationDetails[]> {
        return this.httpClient.get(this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/cases/customers/${workerId}`);
    }

    public getSkdSearch(search: string) {
        return this.httpClient.get(this.helperUrl.getPreventivniUrl() + `/skd/search?query=${search}`);
    }

    public getSkpSearch(search: string) {
        return this.httpClient.get(this.helperUrl.getPreventivniUrl() + `/skp/search?query=${search}`);
    }

    public getEmployees(
        employerId: string,
        { order = 'created_at', orderDirection = 'DESC', limit = 100, offset = 0 }: SortAndPage,
        fullTextSearch?: string,
        forceReload = false,
    ): Observable<EmployeesResponse> {
        if (fullTextSearch === '') {
            fullTextSearch = undefined;
        }
        return this.apollo
            .query({
                query: GET_EMPLOYEES,
                variables: { employerId, order, orderDirection, limit, offset, fullTextSearch } as EmployeesQuery,
                fetchPolicy: forceReload ? 'no-cache' : 'cache-first',
            })
            .pipe(
                map(result => {
                    if (result.errors) {
                        throw result.errors;
                    }
                    return result.data.employees;
                }),
            );
    }

    public getEmployee(id: string): Observable<EmployeeResponse> {
        return this.apollo
            .query({
                query: GET_EMPLOYEE,
                variables: { id },
            })
            .pipe(
                map(({ data: { employee }, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    return employee;
                }),
            );
    }

    public createEmployee(employee: Employee): Observable<EmployeeResponse> {
        return this.apollo
            .mutate({
                mutation: CREATE_EMPLOYEE,
                variables: { employee },
            })
            .pipe(
                map(({ data, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    if (!data?.createEmployee) {
                        throw new Error('Error creating employee');
                    }
                    return data.createEmployee;
                }),
            );
    }

    public updateEmployee(id: string, employee: Employee): Observable<EmployeeResponse> {
        return this.apollo
            .mutate({
                mutation: UPDATE_EMPLOYEE,
                variables: { id, employee },
            })
            .pipe(
                map(({ data, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    if (!data?.updateEmployee) {
                        throw new Error('Error updating employee');
                    }
                    return data.updateEmployee;
                }),
            );
    }

    public deleteEmployee(id: string): Observable<EmployeeDeleteResponse> {
        return this.apollo
            .mutate({
                mutation: DELETE_EMPLOYEE,
                variables: { id },
            })
            .pipe(
                map(({ data, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    if (!data?.deleteEmployee) {
                        throw new Error('Error updating employee');
                    }
                    return data;
                }),
            );
    }

    public getEmployeeExternalId(
        medifitCustomerId: number,
        { limit = 1, offset = 0 }: SortAndPage = { limit: 1, offset: 0 },
        forceReload = true,
    ): Observable<EmployeesResponse> {
        return this.apollo
            .query({
                query: GET_EMPLOYEE_ID_BY_MEDIFIT_CUSTOMER_ID,
                variables: { medifitCustomerId, limit, offset } as EmployeeExternalIdQuery,
                fetchPolicy: forceReload ? 'no-cache' : 'cache-first',
            })
            .pipe(
                map(result => {
                    if (result.errors) {
                        throw result.errors;
                    }
                    return result.data.employees;
                }),
            );
    }
}
