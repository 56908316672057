import { gql } from 'apollo-angular';
import { Employee, EmployeeResponse, EmployeesResponse } from '../../_common/model/employee';

export type EmployeesQuery = {
    employerId: string;
    fullTextSearch?: string;
    order: string;
    orderDirection: string;
    limit: number;
    offset: number;
};

export type EmployeeExternalIdQuery = {
    medifitCustomerId: number;
    limit: number;
    offset: number;
};

export type EmployeesQueryResponse = {
    employees: EmployeesResponse;
};

export const GET_EMPLOYEES = gql<EmployeesQueryResponse, EmployeesQuery>`
    query GetEmployeesByEmployer(
        $employerId: String!
        $fullTextSearch: String
        $order: String
        $orderDirection: String
        $limit: Int!
        $offset: Int!
    ) {
        employees(
            employerId: $employerId
            fullTextSearch: $fullTextSearch
            order: $order
            orderDirection: $orderDirection
            limit: $limit
            offset: $offset
        ) {
            data {
                id
                firstName
                lastName
                email
                emso
                address
                city
                profession
                medifitCustomerId
            }
            metadata {
                currentPage
                totalPages
                totalCount
            }
        }
    }
`;

export type EmployeeQueryResponse = {
    employee: EmployeeResponse;
};

export const GET_EMPLOYEE = gql<EmployeeQueryResponse, { id: string }>`
    query GetEmployee($id: String!) {
        employee(id: $id) {
            data {
                id
                firstName
                lastName
                email
                phoneNumber
                emso
                address
                postCode
                city
                profession
                birthDate
                jobTitle
                jobCode
                gender
                birthPlace
                education
                educationLevel
                skpCode
                personnelNumber
                medifitCustomerId
                workGroupId
                employmentDate
                employmentDateForPosition
                lastExaminationDate
                workplaceDefinition {
                    id
                    name
                }
            }
        }
    }
`;

export type CreateEmployeeMutationResponse = {
    createEmployee: EmployeeResponse;
};

export type EmployeeInput = {
    employee: Employee;
};

export const CREATE_EMPLOYEE = gql<CreateEmployeeMutationResponse, EmployeeInput>`
    mutation CreateEmployee($employee: EmployeeInput!) {
        createEmployee(employee: $employee) {
            data {
                id
            }
        }
    }
`;

export type UpdateEmployeeMutationResponse = {
    updateEmployee: EmployeeResponse;
};

export const UPDATE_EMPLOYEE = gql<UpdateEmployeeMutationResponse, EmployeeInput & { id: string }>`
    mutation UpdateEmployee($id: String!, $employee: EmployeeInput!) {
        updateEmployee(id: $id, employee: $employee) {
            data {
                id
                firstName
                lastName
                email
                phoneNumber
                emso
                address
                postCode
                city
                profession
                birthDate
                jobTitle
                jobCode
                gender
                birthPlace
                education
                educationLevel
                skpCode
                personnelNumber
                medifitCustomerId
                workGroupId
                employmentDate
                employmentDateForPosition
                lastExaminationDate
                workplaceDefinition {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_EMPLOYEE_ID_BY_MEDIFIT_CUSTOMER_ID = gql<EmployeesQueryResponse, EmployeeExternalIdQuery>`
    query GetEmployeesByEmployer($medifitCustomerId: Long!, $limit: Int!, $offset: Int!) {
        employees(medifitCustomerId: $medifitCustomerId, limit: $limit, offset: $offset) {
            data {
                id
            }
            metadata {
                currentPage
                totalPages
                totalCount
            }
        }
    }
`;

export type EmployeeDeleteRequest = {
    id: string;
};

export type EmployeeDeleteResponse = {
    deleteEmployee: boolean;
};

export const DELETE_EMPLOYEE = gql<EmployeeDeleteResponse, EmployeeDeleteRequest>`
    mutation DeleteEmployee($id: String!) {
        deleteEmployee(id: $id)
    }
`;
